import React, { Component } from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './CollectionRangeSlider.components' // example render components - source below

const sliderStyle = {
  position: 'relative',
  width: '100%',
};

class CollectionRangeSlider extends Component {

  render() {
    return (
      <div style={{padding: 32}}>
        <Slider
          mode={2}
          step={1}
          domain={this.props.domain}
          reversed={false}
          rootStyle={sliderStyle}
          // onUpdate={this.onUpdate}
          onChange={this.props.updateTimes}
          values={[this.props.collectionStart, this.props.collectionEnd]}
        >
          <Rail>
            {({getRailProps}) => <SliderRail getRailProps={getRailProps}/>}
          </Rail>
          <Handles>
            {({handles, getHandleProps}) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={this.props.domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({tracks, getTrackProps}) => (
              <div className="slider-tracks">
                {tracks.map(({id, source, target}) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={10}>
            {({ticks}) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length}/>
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    )
  }

}

export default CollectionRangeSlider
