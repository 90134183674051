import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import BookingPrice from "./BookingPrice";

class BookingPriceContainer extends Component {
  render() {
    return <BookingPrice {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  bookingPrice: state.bookingPrice,
  bookingDistance: state.bookingDistance,
  bookingPricesCustomChoices: state.bookingPricesCustomChoices,
  selectedVanSizeIndex: state.bookingGeneral.vanSizeIndex || 0,
  extraTime: state.bookingGeneral.extraTime,
});

export default connect(mapStateToProps, null)(BookingPriceContainer);
