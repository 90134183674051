import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white,
    borderTop: 'none',
    zIndex: 100
  },
  rootNoLine: {
    border: 'none'
  },
  content: {
    maxWidth: 800,
    margin: '0 auto',
    padding: 32,
    paddingTop: 0
  }
});

export default styles;
