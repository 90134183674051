import React, { Component } from "react";
import BookingHeader from "../../../components/booking/BookingHeader/BookingHeader";
import connect from "react-redux/es/connect/connect";
import DatePickerAndCollection from "./DatePickerAndCollection";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { navigate } from "gatsby";
import { window } from "browser-monads";
import { saveLead } from "../../../actions/LeadActions";
import {
  setCollectionRange,
  setPickupDate,
} from "../../../actions/GeneralBookingActions";
import Utils from "../../../library/utils";

const moment = require("moment");

class DatePickerAndCollectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      selectedDay: null,

      // Scrollable days:
      leftArrowDisabled: true,
      rightArrowDisabled: false,
      loadingTimeRangeSlider: false,
      loading: false,
      checkingTimes: false,

      // For collection range:
      collectionStart: 8,
      collectionEnd: 18,
      extraCharge: 0,
      todayMinHour: 6,
      defaultMinHour: 6,
      domain: [6, 22],
      todayDomain: [DatePickerAndCollectionContainer.getMinHour(), 22],
      pickupAvailableToday: DatePickerAndCollectionContainer.getMinHour() <= 21, // no later than 10pm ...

      // Fake drivers available
      driversAvailable: [],
    };

    this.loadingTimeRangeSliderTimeout = null;
    // this.checkingTimesTimeout = null;
    this.scrollableRef = null;
  }

  componentDidMount() {
    this.scrollableRef.addEventListener("scroll", this.handleScroll); // Mobile scroll days

    // Set values from store
    this.setState({
      collectionStart: this.props.bookingGeneral.collectionStart,
      collectionEnd: this.props.bookingGeneral.collectionEnd,
    });

    this.createDayVariables();
    window.scrollTo(0, 0);
  }

  createDayVariables = () => {
    // Setup day variables:
    var today = moment();
    var thirtyDays = today.clone().add(90, "d");
    var days = [];
    var day = today;
    var driversAvailable = [];

    // Create 30 days from today:
    while (day <= thirtyDays) {
      days.push(day.toDate());
      // Fake:
      driversAvailable.push(Utils.getProbalisticNumber(moment(day).day()));
      day = day.clone().add(1, "d");
    }

    this.setState(
      {
        days: days,
        driversAvailable: driversAvailable,
      },
      () => {
        // No arrival date set; user must set one:
        if (!this.props.bookingGeneral.arrival) return;

        // Date selected is in the past, set to null and return:
        if (moment().diff(this.props.bookingGeneral.arrival, "days") > 0) {
          this.setState({ selectedDay: null });
          return;
        }

        // Format day, setup variables
        const selectedDay = moment(this.props.bookingGeneral.arrival).format(
          "D"
        );
        let selectedDayIndex = 0;
        const max = this.state.days.length;

        // Grab index of selected day
        for (let i = 0; i < max; i++) {
          if (moment(this.state.days[i]).format("D") === selectedDay)
            selectedDayIndex = i;
        }
        // console.log({ selectedDayIndex });

        // Set selected day index
        // this.selectDay(selectedDayIndex);
      }
    );
  };

  selectDay = (index) => {
    // console.log({ selectDay: index });
    if (index === this.state.selectedDay) {
      this.setState({ selectedDay: null });
      return;
    }

    if (index === 0 && this.state.pickupAvailableToday) {
      let collectionEnd = DatePickerAndCollectionContainer.getMinHour() + 7;
      if (collectionEnd > 22) collectionEnd = 22;
      const pickupDate = moment(this.state.days[0]).format("YYYY-MM-DD");
      this.updateTimes(
        [DatePickerAndCollectionContainer.getMinHour(), collectionEnd],
        pickupDate
      );
    }

    this.setState(
      {
        selectedDay: index,
        loadingTimeRangeSlider: true,
      },
      () => {
        if (index !== 0) this.calculatePickupDate();
        this.loadingTimeRangeSliderTimeout &&
          clearTimeout(this.loadingTimeRangeSliderTimeout);
        this.loadingTimeRangeSliderTimeout = setTimeout(
          () => this.setState({ loadingTimeRangeSlider: false }),
          1200
        );
      }
    );
  };

  calculatePickupDate = () => {
    const pickupDate = moment(this.state.days[this.state.selectedDay]).format(
      "YYYY-MM-DD"
    );
    // console.log({ SSS: this.state });
    this.props.setPickupDate(pickupDate);
  };

  static getMinHour = () => parseInt(moment().add(1.5, "h").format("HH"));

  goNext = () => {
    ReactGA.event({
      category: "Web booking",
      action: "Has selected the date",
      label: "Date",
    });
    ReactPixel.track("Lead");
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.saveLead();
        setTimeout(() => {
          navigate("/checkout");
        }, 600);
      }
    );
  };

  updateTimes = (array, pickupDate) => {
    const collectionStart = array[0];
    const collectionEnd = array[1];

    // > 6hrs	+£0
    //   5-6hrs	+£5
    //   3-5hrs	+£10
    //   2-3hrs	+£15
    //   1-2hrs	+£20
    //   1hr		+£25

    let extraCharge = 0;
    const difference = collectionEnd - collectionStart;

    // if (difference <= 6) extraCharge = 500;
    // if (difference <= 5) extraCharge = 1000;
    if (difference <= 3) extraCharge = 300;
    if (difference <= 2) extraCharge = 700;
    if (difference <= 1) extraCharge = 1000;

    this.setState(
      {
        collectionStart: collectionStart,
        collectionEnd: collectionEnd,
        extraCharge: extraCharge,
        checkingTimes: true,
      },
      () => {
        this.checkingTimesTimeout && clearTimeout(this.checkingTimesTimeout);
        this.checkingTimesTimeout = setTimeout(() => {
          this.setState({ checkingTimes: false });
          this.props.setCollectionRange(
            this.state.collectionStart,
            this.state.collectionEnd,
            pickupDate
          );
        }, 400);
      }
    );
  };

  render() {
    return (
      <div>
        <BookingHeader title={"Select a day"} backUrl={"/instructions"} />
        <DatePickerAndCollection
          {...this.props}
          updateTimes={this.updateTimes}
          driversAvailable={this.state.driversAvailable}
          extraCharge={this.state.extraCharge}
          checkingTimes={this.state.checkingTimes}
          todayMinHour={this.state.todayMinHour}
          pickupDate={this.state.pickupDate}
          goNext={this.goNext}
          days={this.state.days}
          selectDay={this.selectDay}
          selectedDay={this.state.selectedDay}
          validDateSelected={this.state.selectedDay !== null}
          setScrollRef={this.setScrollRef}
          scrollLeft={this.scrollLeft}
          scrollRight={this.scrollRight}
          leftArrowDisabled={this.state.leftArrowDisabled}
          rightArrowDisabled={this.state.rightArrowDisabled}
          selectedTimeAllowance={this.state.selectedTimeAllowance}
          loadingTimeRangeSlider={this.state.loadingTimeRangeSlider}
          loading={this.state.loading}
          // ArrivalRangeSlider
          domain={this.state.domain}
          todayDomain={this.state.todayDomain}
          collectionStart={this.state.collectionStart}
          collectionEnd={this.state.collectionEnd}
          pickupAvailableToday={this.state.pickupAvailableToday}
          collectionAvailable={
            this.state.selectedDay !== 0 ||
            (this.state.selectedDay === 0 && this.state.pickupAvailableToday)
          }
        />
      </div>
    );
  }

  setScrollRef = (ref) => {
    this.scrollableRef = ref;
  };

  scrollLeft = () => {
    this.scrollableRef.scrollBy({
      top: 0,
      left: -148,
      behavior: "smooth",
    });
  };

  scrollRight = () => {
    this.scrollableRef.scrollBy({
      top: 0,
      left: 148,
      behavior: "smooth",
    });
  };

  handleScroll = (e) => {
    if (
      e.target.scrollLeft - (e.target.scrollWidth - e.target.offsetWidth) ===
      0
    ) {
      this.setState({
        rightArrowDisabled: true,
      });
    } else {
      this.setState({
        rightArrowDisabled: false,
      });
    }
    if (e.target.scrollLeft === 0) {
      this.setState({
        leftArrowDisabled: true,
      });
    } else {
      this.setState({
        leftArrowDisabled: false,
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    bookingGeneral: state.bookingGeneral,
    bookingAddresses: state.bookingAddresses,
    userDetails: state.userDetails,
  };
}

const mapDispatchToProps = {
  setPickupDate: setPickupDate,
  setCollectionRange: setCollectionRange,
  saveLead: saveLead,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerAndCollectionContainer);
