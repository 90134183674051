import React from 'react';
import ArrivalTimeContainer from '../containers/bookingStep3/DatePickerAndCollection/DatePickerAndCollectionContainer';
import Layout from '../containers/gatsby/layout';

const ArrivalPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <ArrivalTimeContainer/>
  </Layout>
);

export default ArrivalPage;
