import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';
import FONTS from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    // paddingBottom: 32
  },
  content: {
    maxWidth: 664 - 32,
    margin: '0 auto',
    paddingTop: 10
  },
  dayPadding: {
    width: 168,
    flex: '0 0 auto',
    [QUERIES.mobile]: {
      width: 32
    }
  },
  selectDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
    height: 164,
    marginTop: 64
  },
  daysRow: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    width: '100%',
    // overflow: 'hidden'
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  arrow: {
    height: 132,
    width: 48,
    color: COLORS.offBlack,
    ':hover': {
      cursor: 'pointer'
    }
  },
  arrowRight: {
    borderLeft: '1px solid whitesmoke',
  },
  arrowLeft: {
    borderRight: '1px solid whitesmoke',
  },
  disabledArrow: {
    opacity: 0.35
  },
  dateContainer: {
    display: 'inline-block',
    boxShadow: '0 8px 16px rgba(0,0,0,0.12)',
    background: COLORS.white,
    width: 112,
    flex: '0 0 auto',
    height: 132,
    marginRight: 16,
    padding: 12,
    borderRadius: 16,
    textAlign: 'center',
    ':hover': {
      cursor: 'pointer'
    }
  },
  selectedDateContainer: {
    background: COLORS.jjdRed,
    color: COLORS.white
  },
  todayOrTomorrowLabel: {
    fontSize: 12,
    opacity: 0.65,
    paddingTop: 8
  },
  todayOrTomorrowLabelSelected: {
    opacity: 0.8
  },
  monthLabel: {
    fontSize: 14,
    color: '#737373',
  },
  monthLabelSelected: {
    opacity: 1,
    color: COLORS.white,
  },
  dayLabel: {
    paddingTop: 4,
    fontWeight: 'normal'
  },
  dateLabel: {
    fontSize: 32,
    fontWeight: 'bold'
  },
  selectedTimeIndicator: {
    paddingTop: 32,
    paddingLeft: 48,
    position: 'relative'
  },
  selectedTimeContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  selectedTimeValue: {
    fontSize: 48,
    paddingTop: 12,
    color: COLORS.jjdGreyish,
    paddingBottom: 12,
    fontWeight: 'normal',
    [QUERIES.mobile]: {
      fontSize: 28
    }
  },
  selectedTimes: {
    margin: 0,
    fontSize: 18,
    fontWeight: 'normal',
    color: COLORS.jjdGreyish,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectTimeContainer: {
    [QUERIES.desktop]: {
      padding: 16
    }
  },
  slideContainer: {
    padding: 16
  },
  slider: {
    width: '100%',
    height: 24,
    background: COLORS.offWhite,
    outline: 'none',
    appearance: 'none',
    '::slider-thumb': {
      height: 32,
      width: 64
    },
  },
  availableCount: {
    color: COLORS.orangeText,
    padding: 16,
    fontWeight: 'bold',
    fontSize: 12,
    paddingRight: 64,
    textAlign: 'right'
  },
  loadingTimesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 235,
    width: '100%',
    [QUERIES.mobile]: {
      height: 120
    }
  },
  loadingTimes: {
    color: COLORS.jjdGreyish
  },
  circularProgress: {
    color: '#cfcfcf',
    marginRight: 12
  },
  circularProgressContainer: {
    padding: 12,
    paddingLeft: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  vansAvailableIcon: {
    color: COLORS.kgGreen,
    fontSize: 18,
    marginLeft: 12
  },
  extraChargeValue: {
    color: COLORS.jjdRed,
    fontWeight: 'bold',
    padding: 12,
    fontSize: 32,
    position: 'absolute',
    top: 16,
    right: 32
  },
  availableIcon: {
    fontSize: 18,
    marginRight: 6
  },
  availableContainer: {
    textAlign: 'center',
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 14,
    color: COLORS.lightBlack
  },
  confirmBtn: {
    borderRadius: 48,
    maxWidth: 340,
    margin: '16px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    // fontFamily: FONTS.content,
    fontSize: 18,
  }
});

export default styles;
