import React from "react";
import styles from "./BookingContinueButton.styles";
import { css } from "aphrodite";
import PrimaryButton from "../../buttons/PrimaryButton/PrimaryButton";

const BookingContinueButton = (props) => (
  <div className={css(styles.root, props.noLine && styles.rootNoLine)}>
    <div className={css(styles.content)}>
      <div className={css(styles.nextButtonContainer)}>
        <PrimaryButton
          loading={props.loading}
          disabled={props.disabled}
          onClick={props.goNext}
          className={`${props.className}`}
          title={props.continueTitle || "Continue"}
          large={true}
          style={{ width: "100%", marginTop: 16 }}
        />
      </div>
    </div>
  </div>
);

export default BookingContinueButton;
