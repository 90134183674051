import { StyleSheet } from "aphrodite";
import COLORS from "../../../styles/colors";

const styles = StyleSheet.create({
  root: {
    padding: 16,
    position: "relative",
    paddingBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
  },
  loadingNewPriceContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255,255,255,0.65)",
    transition: "250ms",
    opacity: 0,
  },
  loadingNewPriceVisible: {
    opacity: 1,
  },
  progress: {
    color: "rgba(0,0,0,0.35)",
  },
  content: {
    paddingRight: 32,
    paddingLeft: 32,
    display: "flex",
    flexDirection: "row",
  },
  priceLabel: {
    display: "flex",
    flexDirection: "row",
    color: "#a5a5a5",
    position: "relative",
    paddingLeft: 16,
  },
  priceIcon: {
    fontSize: 16,
    color: COLORS.jjdRed,
    marginRight: 6,
    display: "none",
  },
  priceLabelText: {
    fontSize: 12,
    paddingRight: 24,
  },
  priceText: {
    height: 32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "right",
    color: COLORS.jjdRed,
    fontWeight: "normal",
    fontSize: 35,
  },
  durationText: {
    height: 32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    color: COLORS.lightBlack,
    fontWeight: "bold",
    fontSize: 12,
  },
  priceTextNA: {
    color: "#a5a5a5",
  },
  linearProgressContainer: {
    paddingTop: 12,
    paddingRight: 12,
  },
  linearProgress: {
    width: 36,
    position: "absolute",
    top: 12,
    opacity: 0.32,
    borderRadius: 2,
  },
  priceTextSpan: {
    color: COLORS.greyishSpan,
    fontSize: 14,
    margin: "0 auto",
    textAlign: "center",
  },
});

export default styles;
