import React from "react";
import styles from "./BookingPrice.styles";
import { css } from "aphrodite";
import Utils from "../../../library/utils";
import PriceIcon from "@material-ui/icons/ShoppingCartTwoTone";
import DrivingIcon from "@material-ui/icons/CommuteRounded";
import LiftingIcon from "@material-ui/icons/TimerRounded";
var moment = require("moment");

const BookingPrice = (props) => {
  // final price
  // const { selectedVanSizeIndex, bookingPricesCustomChoices } = props;
  // Getting selected vehicle estimate price
  /* const durationInMinutes = moment.duration(
    props.bookingDistance.duration,
    "minutes"
  ); */

  const durationInMinutesMoment = moment.duration(
    props.bookingDistance.duration,
    "minutes"
  );

  const finalPrice = Utils.getFinalPrice(
    props.selectedVanSizeIndex,
    props.bookingPricesCustomChoices
  );

  const extaTimeInMinutes = props.extraTime * 60;
  const durationInMinutes = props.bookingDistance.duration;

  let tempDurationInMinutes = durationInMinutes;

  // minimum 2 hours time
  if (tempDurationInMinutes < 120) {
    tempDurationInMinutes = 120;
  }

  let totalBookedTimeInMinutes = tempDurationInMinutes + extaTimeInMinutes;

  // minimum 2 hours time
  if (totalBookedTimeInMinutes < 120) {
    totalBookedTimeInMinutes = 120;
  }

  const totalTimeBooked = moment.duration(
    props.bookingDistance.minimumTimeHours + props.extraTime,
    "hours"
  );

  /*  console.log({
    totalBookedTimeInMinutes,
    totalBookedTimeInMinutes,
    extaTimeInMinutes,
    tempDurationInMinutes,
    durationInMinutes,
    props,
  }); */

  return (
    <div className={css(styles.root)}>
      <div
        className={css(
          styles.loadingNewPriceContainer
          // props.bookingPrice.estimatingNewPrice && styles.loadingNewPriceVisible
        )}
      >
        {/*<CircularProgress className={css(styles.progress)} size={14} thickness={10} />*/}
      </div>

      {props.includeDrivingTime && (
        <div className={css(!finalPrice && styles.priceTextNA)}>
          <div className={css(styles.durationText)}>
            <div style={{ width: 32 }} />
            <DrivingIcon className={css(styles.priceIcon)} />
            {`Total booked time: ${moment
              .utc(totalTimeBooked.asMilliseconds())
              .format("H[h]mm")}`}
          </div>
        </div>
      )}

      <div className={css(styles.content)}>
        <div className={css(!finalPrice && styles.priceTextNA)}>
          <div className={css(styles.priceText)}>
            <PriceIcon className={css(styles.priceIcon)} />
            {`${Utils.getPoundsAndPennies(finalPrice)}`}
          </div>
        </div>
      </div>
      {/* <p className={css(styles.priceTextSpan)}>(Minimum 2 hours)</p> */}
    </div>
  );
};

export default BookingPrice;
