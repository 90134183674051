import React from "react";
import styles from "./DatePickerAndCollection.styles";
import { css } from "aphrodite";
import ArrowLeft from "@material-ui/icons/ArrowLeftRounded";
import ArrowRight from "@material-ui/icons/ArrowRightRounded";
import BookingContinueButton from "../../../components/booking/BookingContinueButton/BookingContinueButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CheckCircle from "@material-ui/icons/CheckCircleOutlineRounded";
import UnavailableIcon from "@material-ui/icons/BlockOutlined";
import CollectionRangeSlider from "../CollectionRangeSlider/CollectionRangeSlider";
import { FONTS } from "../../../styles/fonts";
import Utils from "../../../library/utils";
import BookingPriceContainer from "../../../components/booking/BookingPrice/BookingPriceContainer";
import { KIFFGO } from "../../../library/kiffgo";
import ExcellentReviews from "../../../components/booking/ExcellentReviews/ExcellentReviews";
import GiveBernyACall from "../../../components/booking/GiveBernyACall/GiveBernyACall";

const moment = require("moment");

const DatePickerAndCollection = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.selectDateContainer)}>
        <ArrowLeft
          className={css(
            styles.arrow,
            styles.arrowLeft,
            props.leftArrowDisabled && styles.disabledArrow
          )}
          onClick={props.scrollLeft}
        />
        <div className={css(styles.daysRow)} ref={props.setScrollRef}>
          <div className={css(styles.dayPadding)} />
          {props.days.map((date, index) => {
            const selected = props.selectedDay === index;
            return (
              <div
                key={index}
                className={css(
                  styles.dateContainer,
                  selected && styles.selectedDateContainer
                )}
                onClick={() => props.selectDay(index)}
              >
                <div
                  className={css(
                    styles.monthLabel,
                    selected && styles.monthLabelSelected
                  )}
                >
                  {moment(date).format("MMM")}
                </div>
                <div className={css(styles.dateLabel)}>
                  {moment(date).format("D")}
                </div>
                <div className={css(styles.dayLabel)}>
                  {moment(date).format("ddd")}
                </div>
                {(index === 0 || index === 1) && (
                  <div
                    className={css(
                      styles.todayOrTomorrowLabel,
                      selected && styles.todayOrTomorrowLabelSelected
                    )}
                  >
                    {index === 0 ? "Today" : "Tomorrow"}
                  </div>
                )}
              </div>
            );
          })}
          <div style={{ width: 16, flex: "0 0 auto" }} />
        </div>
        <ArrowRight
          className={css(
            styles.arrow,
            styles.arrowRight,
            props.rightArrowDisabled && styles.disabledArrow
          )}
          onClick={props.scrollRight}
        />
      </div>

      {props.loadingTimeRangeSlider && (
        <div className={css(styles.loadingTimesContainer)}>
          <CircularProgress
            size={20}
            thickness={10}
            className={css(styles.loadingTimes)}
          />
        </div>
      )}

      {!props.loadingTimeRangeSlider && props.selectedDay !== null && (
        <div className={css(styles.selectTimeContainer)}>
          <div className={css(styles.selectedTimeIndicator)}>
            {props.collectionAvailable && (
              <div className={css(styles.availableContainer)}>
                <CheckCircle className={css(styles.availableIcon)} />
                <div>
                  {props.driversAvailable[props.selectedDay]} drivers available!
                </div>
              </div>
            )}
            {!props.collectionAvailable && (
              <div className={css(styles.availableContainer)}>
                <UnavailableIcon className={css(styles.availableIcon)} />
                <div>Unavailable</div>
              </div>
            )}
            {props.collectionAvailable && (
              <h3
                style={{
                  fontFamily: FONTS.Content,
                  paddingBottom: 8,
                  fontWeight: 600,
                }}
              >
                Please choose van arrival time slot:
              </h3>
            )}
            {props.collectionAvailable && (
              <div className={css(styles.selectedTimes)}>
                {props.collectionStart >= 12
                  ? `${
                      props.collectionStart > 12
                        ? props.collectionStart - 12
                        : props.collectionStart
                    }pm`
                  : `${props.collectionStart}am`}
                {` - `}
                {props.collectionEnd >= 12
                  ? `${
                      props.collectionEnd > 12
                        ? props.collectionEnd - 12
                        : props.collectionEnd
                    }pm`
                  : `${props.collectionEnd}am`}
              </div>
            )}

            {!props.collectionAvailable && (
              <p style={{ margin: 0, marginTop: 8, fontSize: 12 }}>
                We're sorry, we have no vans left available for today.
              </p>
            )}
            {!props.collectionAvailable && (
              <p style={{ margin: 0, marginTop: 8, fontSize: 12 }}>
                If you desperately need something moved today, please{" "}
                <a
                  className={css(styles.phoneNumber)}
                  //href="javascript:void(Chatra('openChat', true))"
                >
                  contact us
                </a>{" "}
                and we'll see what we can do.
              </p>
            )}

            {props.extraCharge > 0 && (
              <div
                className={css(styles.extraChargeValue)}
              >{` +£${Utils.getPounds(props.extraCharge)}`}</div>
            )}
          </div>

          <div className={css(styles.slideContainer)}>
            {props.selectedDay !== 0 && (
              <CollectionRangeSlider
                updateTimes={props.updateTimes}
                collectionStart={props.collectionStart}
                collectionEnd={props.collectionEnd}
                domain={props.domain}
              />
            )}
            {props.selectedDay === 0 && props.pickupAvailableToday && (
              <CollectionRangeSlider
                updateTimes={props.updateTimes}
                collectionStart={props.collectionStart}
                collectionEnd={props.collectionEnd}
                domain={props.todayDomain}
              />
            )}
          </div>
        </div>
      )}

      {props.collectionAvailable &&
        props.selectedDay !== null &&
        !props.loadingTimeRangeSlider && (
          <BookingPriceContainer includeDrivingTime={true} />
        )}

      <BookingContinueButton
        {...props}
        className={css(styles.confirmBtn)}
        continueTitle={"Confirm"}
        disabled={
          !props.collectionAvailable ||
          !props.validDateSelected ||
          props.loadingTimes ||
          props.checkingAvailability
        }
      />

      <GiveBernyACall />
    </div>
    <ExcellentReviews />
  </div>
);

export default DatePickerAndCollection;
